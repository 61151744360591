import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Title from "../../components/Admin/Title";
import useIsAdmin from "../../hooks/useIsAdmin";
import ErrorPage from "../ErrorPage";
import { useUser } from "../../components/UserContext";
import IndividualSessions from "../../components/Admin/IndividualSessions";

const Config = () => {
  const { access } = useIsAdmin();
  document.title = "LVF1 - Admin Config";
  const { user } = useUser() as any;
  const token = window.localStorage.getItem("userLogged");
  const [config, setConfig] = useState({
    maintenance: false,
    show_main_transfers: false,
    show_secondary_transfers: false,
    show_secondary_champ: false,
    show_event: false,
    secondary_type_races: "",
    claims_over: [""],
    main_race: [""],
    secondary_claims_over: [""],
    secondary_race: [""],
    event_claims_over: [""],
    event_race: [""],
  });
  const [totalMainDates, setTotalMainDates] = useState(1);
  const [totalSecDates, setTotalSecDates] = useState(1);
  const [totalEveDates, setTotalEveDates] = useState(1);

  const [validated, setValidated] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [typeRacesSec, setTypeRacesSec] = useState();
  const [secIndividual, setSecIndividual] = useState({
    slots: null,
    dates: [],
  });

  useEffect(() => {
    axios
      .get(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/general/config`
      )
      .then(({ data }) => {
        setConfig(data.config);
        setTotalMainDates(data.config.main_race.length);
        setTotalSecDates(data.config.secondary_race.length);
        setTotalEveDates(data.config.event_race.length);
        setTypeRacesSec(data.config.secondary_type_races);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }, []);

  const submitForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (!(form !== null && form.checkValidity() === false)) {
      setUploading(true);
      if (token == null) return;
      axios
        .post(
          `${
            process.env.NODE_ENV === "development"
              ? "http://localhost:5500"
              : "https://ligavirtualf1.es:5500"
          }/api/admin/updateConfig`,
          {
            config,
            secIndividual,
            user,
            token: JSON.parse(token).token,
          }
        )
        .then(() => {
          toast.success("Config updated successfully");
          setUploading(false);
          setValidated(false);
        })
        .catch(({ response }) => {
          toast.error(response);
          setUploading(false);
          setValidated(false);
          throw new Error(response);
        });
    }
    setValidated(true);
  };

  const removeSignsPicker = (type_championship: number) => {
    if (token == null) return;
    axios
      .post(
        `${
          process.env.NODE_ENV === "development"
            ? "http://localhost:5500"
            : "https://ligavirtualf1.es:5500"
        }/api/admin/removeSignsPicker`,
        {
          user,
          token: JSON.parse(token).token,
          type_championship,
        }
      )
      .then(() => {
        toast.success("Signs picker table removed successfully");
        setUploading(false);
        setValidated(false);
      })
      .catch(({ response }) => {
        toast.error(response);
        setUploading(false);
        setValidated(false);
        throw new Error(response);
      });
  };

  const handleInputChange = (e: any, value: string) => {
    const { name } = e.target;
    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
  };

  const jsxClaimsOver = (
    type: "claims_over" | "secondary_claims_over" | "event_claims_over"
  ) => {
    const date = new Date(config[type][0]);
    //We get time
    const time =
      date.getUTCHours().toString().padStart(2, "0") +
      ":" +
      date.getUTCMinutes().toString().padStart(2, "0");
    // We get date
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Sumamos 1 porque los meses comienzan desde 0
    const day = date.getUTCDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    return (
      <Form.Group className="form-group" as={Col} md="4">
        <Form.Label>Claims Over Time (CET)</Form.Label>
        <Form.Control
          type="date"
          value={formattedDate}
          onChange={(e: any) => {
            const [newYear, newMonth, newDate] = e.target.value.split("-");
            date.setUTCFullYear(parseInt(newYear, 10));
            date.setUTCMonth(parseInt(newMonth, 10) - 1);
            date.setUTCDate(parseInt(newDate, 10));

            const updatedConfig = { ...config };
            updatedConfig[type][0] = date.toISOString();
            setConfig(updatedConfig);
          }}
        ></Form.Control>
        <Form.Control
          type="time"
          onChange={(e: any) => {
            const [newHours, newMinutes] = e.target.value.split(":");
            date.setUTCHours(parseInt(newHours, 10));
            date.setUTCMinutes(parseInt(newMinutes, 10));
            const updatedConfig = { ...config };
            updatedConfig[type][0] = date.toISOString();
            setConfig(updatedConfig);
          }}
          value={time}
        />
        <Form.Control.Feedback type="invalid">
          Field required
        </Form.Control.Feedback>
      </Form.Group>
    );
  };

  const jsxRaceDates = (
    type: "main_race" | "secondary_race" | "event_race"
  ) => {
    let total;
    let dates;
    let attr = type;
    if (type === "main_race") {
      total = totalMainDates;
      dates = config.main_race;
    } else if (type === "secondary_race") {
      total = totalSecDates;
      dates = config.secondary_race;
    } else {
      total = totalEveDates;
      dates = config.event_race;
    }

    const JSXelements = [];
    for (var i = 0; i < total; i++) {
      const date = new Date(dates[i]);
      //We get time
      const time =
        date.getUTCHours().toString().padStart(2, "0") +
        ":" +
        date.getUTCMinutes().toString().padStart(2, "0");
      // We get date
      const year = date.getUTCFullYear();
      const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Sumamos 1 porque los meses comienzan desde 0
      const day = date.getUTCDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const renderElement = (i: number) => {
        return (
          <Form.Group className="form-group" as={Col} md="4">
            <Form.Label>Session {i + 1} Time (CET)</Form.Label>
            <Form.Control
              type="date"
              disabled={i === 0}
              value={formattedDate}
              onChange={(e: any) => {
                const [newYear, newMonth, newDate] = e.target.value.split("-");
                date.setUTCFullYear(parseInt(newYear, 10));
                date.setUTCMonth(parseInt(newMonth, 10) - 1);
                date.setUTCDate(parseInt(newDate, 10));

                const updatedConfig = { ...config };
                updatedConfig[attr][i] = date.toISOString();
                setConfig(updatedConfig);
              }}
            ></Form.Control>
            <Form.Control
              type="time"
              onChange={(e: any) => {
                const [newHours, newMinutes] = e.target.value.split(":");
                date.setUTCHours(parseInt(newHours, 10));
                date.setUTCMinutes(parseInt(newMinutes, 10));
                const updatedConfig = { ...config };
                updatedConfig[attr][i] = date.toISOString();
                setConfig(updatedConfig);
              }}
              value={time}
            />
            <Form.Control.Feedback type="invalid">
              Field required
            </Form.Control.Feedback>
          </Form.Group>
        );
      };
      JSXelements.push(renderElement(i));
    }
    return JSXelements;
  };

  const updateConfigDates = (
    value: string,
    races: "main_race" | "secondary_race" | "event_race"
  ) => {
    setConfig((prev_config) => {
      const diff = parseInt(value) - prev_config[races].length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          const defaultValue =
            prev_config[races].length > 0
              ? prev_config[races][0]
              : "2023-01-01T00:00:00Z";
          prev_config[races].push(defaultValue);
        }
      } else if (diff < 0) {
        for (let i = diff; i < 0; i++) {
          prev_config[races].pop();
        }
      }
      return prev_config;
    });
  };

  if (!access) return <ErrorPage />;
  return (
    <div className="full-height padding-md app-container flex-center-top page-transition">
      <div className="form-container">
        <Title text="Config" />
        <small className="mandatory-fields">(*) Required fields</small>
        <Form noValidate validated={validated} onSubmit={submitForm}>
          <Row className="mb-3">
            <Form.Group className="form-group" as={Col} md="3">
              <Form.Check
                type="switch"
                label="Maintenance"
                name="maintenance"
                checked={config.maintenance}
                onChange={(e: any) => {
                  handleInputChange(e, e.target.checked);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="4">
              <Form.Check
                type="switch"
                label="Show Main Transfers"
                name="show_main_transfers"
                checked={config.show_main_transfers}
                onChange={(e: any) => {
                  handleInputChange(e, e.target.checked);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="5">
              <Form.Check
                type="switch"
                label="Show Secondary Transfers"
                name="show_secondary_transfers"
                checked={config.show_secondary_transfers}
                onChange={(e: any) => {
                  handleInputChange(e, e.target.checked);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="5">
              <Form.Check
                type="switch"
                label="Show Secondary Championship"
                name="show_secondary_champ"
                checked={config.show_secondary_champ}
                onChange={(e: any) => {
                  handleInputChange(e, e.target.checked);
                }}
              />
            </Form.Group>
            <Form.Group className="form-group" as={Col} md="5">
              <Form.Check
                type="switch"
                label="Show Event"
                name="show_event"
                checked={config.show_event}
                onChange={(e: any) => {
                  handleInputChange(e, e.target.checked);
                }}
              />
            </Form.Group>
          </Row>

          <h4>
            Main Championship{" "}
            <Button variant="warning" onClick={() => removeSignsPicker(1)}>
              Remove Signs Picker Table
            </Button>
          </h4>
          <Row className="mb-3">
            {jsxClaimsOver("claims_over")}
            <Form.Group className="form-group" as={Col} md="4">
              <Form.Label>Race Dates</Form.Label>
              <Form.Control
                required
                type="number"
                min="1"
                value={totalMainDates}
                onChange={(e: any) => {
                  if (e.target.value < 1) return;
                  setTotalMainDates(e.target.value);
                  updateConfigDates(e.target.value, "main_race");
                }}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            {jsxRaceDates("main_race")}
          </Row>

          <h4>
            Secondary Championship{" "}
            <Button variant="warning" onClick={() => removeSignsPicker(3)}>
              Remove Signs Picker Table
            </Button>
          </h4>
          <Row className="mb-3">
            {jsxClaimsOver("secondary_claims_over")}
            <Form.Group className="form-group" as={Col} md="4">
              <Form.Label>Type Races</Form.Label>
              <Form.Select
                name="secondary_type_races"
                value={typeRacesSec}
                required
                onChange={(e: any) => {
                  setTypeRacesSec(e.target.value);
                  handleInputChange(e, e.target.value);
                }}
              >
                <option value="group">Grouped sessions</option>
                <option value="individual">Individual sessions *</option>
              </Form.Select>
              {typeRacesSec === "individual" && (
                <p>* Current Sign ups will be removed</p>
              )}
            </Form.Group>
            {typeRacesSec === "group" ? (
              <>
                <Form.Group className="form-group" as={Col} md="4">
                  <Form.Label>Race Dates</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    min="1"
                    value={totalSecDates}
                    onChange={(e: any) => {
                      if (e.target.value < 1) return;
                      setTotalSecDates(e.target.value);
                      updateConfigDates(e.target.value, "secondary_race");
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    Field required
                  </Form.Control.Feedback>
                </Form.Group>
                {jsxRaceDates("secondary_race")}
              </>
            ) : (
              <IndividualSessions
                secIndividual={secIndividual}
                setSecIndividual={setSecIndividual}
              />
            )}
          </Row>

          <h4>Event</h4>
          <Row className="mb-3">
            {jsxClaimsOver("event_claims_over")}
            <Form.Group className="form-group" as={Col} md="4">
              <Form.Label>Race Dates</Form.Label>
              <Form.Control
                required
                type="number"
                min="1"
                value={totalEveDates}
                onChange={(e: any) => {
                  if (e.target.value < 1) return;
                  setTotalEveDates(e.target.value);
                  updateConfigDates(e.target.value, "event_race");
                }}
              />
              <Form.Control.Feedback type="invalid">
                Field required
              </Form.Control.Feedback>
            </Form.Group>
            {jsxRaceDates("event_race")}
          </Row>

          <div className="text-center">
            {uploading ? (
              <FontAwesomeIcon icon={faSpinner} className="spinner-button" />
            ) : (
              <Button variant="primary" type="submit">
                Update Config
              </Button>
            )}
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Config;
